const Buttons = [
  {
    title: 'All',
    id: 0,
  },
  {
    title: 'BackEnd',
    id: 1,
  },
  {
    title: 'FrontEnd',
    id: 2,
  },
  {
    title: 'Mobile',
    id: 3,
  },
];

export default Buttons;
